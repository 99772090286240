.nason-layout{
  min-height: 100vh;
  width: 100vw;
  .nason-header{
    background-color:  white;
    height: $headerLayoutHeight;
    padding-inline: $px60;
    padding-right: $px80;
    border-bottom: $px1 solid #CFD9E0;
    &.nason-header-mobile{
      height: calc(2 * #{$headerLayoutHeight});
    }
    .header-wrapper{
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      justify-items: center;
      .logo-header{
        height: 100%;
        line-height: 0;
        margin-left: -$px20;
        padding: $px8 0 $px8 $px40;
        svg{
          height: 100%;
          width: auto;
          cursor: pointer;
        }
      }
      .content-header{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 100%;
        flex: 1;
        .ant-space{
          line-height: 0;
        }
        .menu-header{
          justify-content: flex-end;
          background-color: transparent;
          color: #000000;
          flex: 1;
          font-size: $px20;
          font-weight: 500;
          line-height: $px24;
          outline: none;
          .ant-menu-item{
            min-width: $px100;
            outline: none;
            padding-inline: $px16;
            &.ant-menu-item-selected,
            &:hover{
              background-color: transparent;
              color: #FA8A07
            }
          }

          .ant-menu-submenu{
            background-color: transparent;
            padding-inline: $px16;
            &.ant-menu-submenu-selected div,
            &.ant-menu-submenu-active,
            &:hover{
              background-color: transparent;
              color: #FA8A07;
            }
            .ant-menu-submenu-title{
              outline: none;
            }
          }
        }
      }
      // menu mobile
      &.header-mobile-layout{
        justify-content: space-between;
        .menu-mobile-icon{
          cursor: pointer;
          width: $px80;
          height: $px80;
          svg{
            width: auto;
            height: 100%;
          }
        }
        .menu-mobile-container{
          position: fixed;
          right: 0;
          top: 0;
          width: 100vw;
          height: 100vh;
          z-index: 10000;
          background-color: rgba(0, 0, 0, 0.3);
          transform: translateX(100vw);
          transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
          overflow: hidden;
          display: flex;
          justify-content: flex-end;
          opacity: 0;
          &.active{
            transform: translateX(0);
            opacity: 1;
          }
          .menu-mobile-content{
            height: 100vh;
            width: 80vw;
            background-color: #ffffff;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            padding: $px40 $px80;
            .menu-header-mobile{
              border-inline-end: none;
              font-size: $px80;
              margin-top: $px24;
              .ant-menu-item{
                padding-left: $px96 !important;
              }
              .ant-menu-item,
              .ant-menu-submenu-title{
                padding-left: $px90 !important;
                height: $px160;
                line-height: $px160;
                padding-inline-end: calc(2 * #{$px64});
                padding-inline: $px64;
                margin-inline: $px16;
                margin-block: $px16;
                width: calc(100% - #{$px16});
                border-radius: $px32;
                outline: none;
                &:hover,
                &.ant-menu-item-selected{
                  background-color: transparent;
                  color: #FA8A07;
                }
                .ant-menu-submenu-arrow{
                  transform: scale(4);
                }
              }
            }
          }
        }
      }
    }
  }
  .nason-footer{
    width: 100%;
    padding-inline: $px80;
    padding-top: $px60;
    padding: $px24 $px50;
    border-top: $px1 solid #CFD9E0;
    box-shadow: 0 0 $px8 rgba(0, 0, 0, 0.1);
    color: #000000 !important;
    .footer-content-top{
      padding: 0 $px40;
      display: flex;
      justify-content:space-between;
      .content-item{
        h2{
          font-size: $px18;
        }
        .list-item{
          list-style: none;
          margin-top: $px12;
          margin-bottom: $px12;
          padding-left: 0;
          li{
            margin: $px8 0;
            font-size: $px16 !important;
            .ant-col{
              font-size: $px16 !important;
              a{
                color: #000000 !important;
              }
            }
          }
        }
        .list-social-icon{
          margin-top: $px16;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          div{
              height: $px30;
              width: $px30;
              margin-right: $px8;
              cursor: pointer;
              background-repeat: no-repeat;
              background-size: cover;
          }
        }
        &+.content-item{
          margin-left: $px12;
        }
      }
    }
    .ant-divider-horizontal{
      height: $px1;
      background-color: rgba(0, 0, 0, 0.1);
      margin: $px24 0;
    }
    .footer-content-bottom{
      text-align: center;
      font-size: $px14;
    }
    &.nason-footer-mobile{
      padding: $px100;
      padding-bottom: $px48;
      .footer-content-top{
        padding: 0;
        display: flex;
        flex-direction: column-reverse;
        justify-content:space-between;
        text-align: center;
        .content-item{
          &+.content-item{
            margin-bottom: $px40;
          }
          h2{
            font-size: $px36;
          }
          .list-item{
            list-style: none;
            margin-top: $px24;
            margin-bottom: $px24;
            padding-left: 0;
            li{
              margin: $px16 0;
              font-size: $px32 !important;
              .ant-col{
                font-size: $px32 !important;
                a{
                  color: #000000 !important;
                }
              }
            }
          }
          .list-social-icon{
            margin-top: $px60;
            display: flex;
            justify-content: center;
            align-items: center;
            a{
              margin: 0 $px24;
            }
            div{
                height: $px60;
                width: $px60;
                cursor: pointer;
                background-repeat: no-repeat;
                background-size: cover;
            }
          }
        }
      }
      .ant-divider-horizontal{
        height: $px2;
        background-color: rgba(0, 0, 0, 0.1);
        margin: $px48 0;
      }
      .footer-content-bottom{
        text-align: center;
        font-size: $px28;
      }
    }
  }
  .scroll-top-btn{
    width: $px44; 
    height: $px44; 
    position: fixed;
    z-index: 1000;
    bottom: $px20;
    right: $px20;
    cursor: pointer;
    circle {
      fill: rgb(250, 138, 7);
    }
    &:hover{
      circle {
        fill: rgba(250, 138, 7, 0.8);
      }
    }
    &.scroll-top-btn-mobile{
      width: $px160; 
      height: $px160; 
    }
  }
}

.ant-menu.ant-menu-sub.ant-menu-vertical{
  background-color: white;
  font-weight: 500;
  min-width: 0;
  border: $px1 solid #CFD9E0;
  box-shadow: 0 0 $px8 rgba(0, 0, 0, 0.1);
  padding: $px4;
  .ant-menu-item-only-child{
    color: #000000 !important;
    width: 100%;
    font-size:  $px18;
    margin: 0;
    outline: none !important;
    padding: 0 $px32;
    height: $px40;
    line-height: $px40;
    padding-inline: $px16;
    margin-block: $px4;
    border-radius: $px4;

    &.ant-menu-item-active,
    &:hover{
      background-color: #FFAE4F !important;
    }

    &.ant-menu-item-selected{
      background-color: #FA8A07;
    }
  }
}