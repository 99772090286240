.main-container{
    min-height: 100vh;
    width: 100vw;

    .section-wrapper{
        width: 100vw;
        height: 100vh;
        display: flex;
        background-attachment: fixed;
        background-repeat: no-repeat;
        background-size: cover;
        .left-info{
            width: 60%;
            color: white;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: start;
            padding-left: $px120;
            h1{
                font-size: $px35;
                font-weight:  700;
            }
            h2{
                font-size: $px40;
                font-weight: 600;
            }
            p{
                font-size: $px22;
                font-weight:  600;
            }
            .description{
                font-size: $px18;
                font-weight: 400;
                line-height: $px22;
            }
            .detail-btn{
                width: 100%;
                display:flex;
                justify-content: flex-end; 
                z-index: 100;
                a{
                    padding: $px12;
                    border-radius: $px12;
                    font-size: $px18;
                    font-weight: 400;
                    line-height: $px22;
                    color: #FFFFFF;   
                    &:hover{
                        background-color: #1e8dc1;                        ;
                    }                 
                }
            }
            .avic-btn{
                height: $px60;
                width: $px200;
                border-radius: $px30;
                border: $px3 solid #ffffff;
                color: #ffffff !important;
                font-size: $px20 !important;
                font-weight: 600 !important;
                background: linear-gradient(269.97deg, rgba(255, 61, 0, 0.935857) -7.8%, #FA8A07 95.56%, #FA8A07 95.56%), linear-gradient(0deg, #FFFFFF, #FFFFFF) !important;
                &:hover{                
                    background: linear-gradient(269.97deg, rgba(255, 61, 0, 0.8) -7.8%, rgba(250, 138, 7, 0.8) 95.56%, rgba(250, 138, 7, 0.8) 95.56%), linear-gradient(0deg, #FFFFFF, #FFFFFF) !important;
                }
            }
            &.left-info-company{
                h3{
                    font-size: $px30;
                    font-weight: 600;
                }
                p{
                    font-size: $px20;
                    font-weight:  600;
                }
                .avic-btn{
                    height: $px60;
                    width: $px270;
                    border-radius: $px30;
                    border: $px3 solid #FA8A07;
                    color: #FA8A07 !important;
                    font-size: $px25 !important;
                    font-weight: 600 !important;
                    background: #ffffff !important;
                    position: relative;
                    margin-bottom: $px20;
                    cursor: text;
                    &::after{
                        content: "";
                        display: block;
                        width: $px286;
                        height: $px75;
                        border-radius: $px40;
                        border: $px5 solid #FFFFFF; 
                        background-color: transparent;  
                        position: absolute;
                        top: -$px10;                     
                        left: -$px10;                     
                    }
                }
            }
        }
        .center-info{
            width: 100%;
            color: white;
            padding: 0 $px120;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            h1{
                font-size: $px40;
                font-weight: 700;
                margin-top: $px40;
            }
            h2{
                font-size: $px30;
                font-weight: 500;
                margin: $px60 0 $px80;
                color: #000000;
                line-height: $px36;
            }

            .list-block{
                width: 100%;
                display: flex;
                flex: 1;
                justify-content:space-between;

                .list-block-item{
                    width: 30%;
                    height: $px350;
                    padding: 0 $px40;
                    text-align: center;
                    border: $px2 solid #006FA1;
                    &.border-orange{
                        border-color: #FA8A07;
                    }
                    .list-block-item_icon{
                        width: $px60;
                        height: $px60;
                        margin: $px32 0;
                        &.icon_large{
                            width: auto;
                            height: $px96;
                        }
                    }
                    .list-block-item_title{
                        font-size: $px25;
                        font-weight: 600;
                        line-height: $px30;
                        color: #000000;
                        min-height: $px60;
                    }
                    .list-block-item_description{
                        font-size: $px20;
                        font-weight: 400;
                        line-height: $px24;
                        color: #000000;
                        margin: $px32 0;
                    }
                }
            }

            .div-section2{
                width: 90%;
                font-size: $px22;
                font-weight: 500;
                text-align: center;
            }

            .avic-btn{
                height: $px86;
                width: $px340;
                border-radius: $px50;
                border: $px5 solid #ffffff;
                color: #ffffff !important;
                font-size: $px30 !important;
                font-weight: 700 !important;
                background: transparent !important;
                margin-bottom: $px20;
                cursor: text;
            }
        }
        .right-info{
            height: 100%;
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-right: 10%;
            color: #ffffff;
            &.section5{
                padding-right: 10%;
            }
            &.section8{
                align-items: flex-start;
            }
            h1{
                font-size: $px40;
                font-weight: 600;
                line-height: $px48;
            }

            .text-right{
                font-size: $px18;
                font-weight: 400;
                line-height: $px22;
                text-align: right;
            }

            .detail-btn{
                width: 100%;
                display:flex;
                justify-content: flex-end;
                a{
                    padding: $px12;
                    border-radius: $px12;
                    font-size: $px18;
                    font-weight: 400;
                    line-height: $px22;
                    color: #FFFFFF;   
                    &:hover{
                        background-color: #1e8dc1;                        ;
                    }                 
                }
            }

            img{
                height: $px540;
                width: auto;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
            }

            .form-wrapper{
                width: 80%;
                background-color: #ffffff;
                padding: $px20 $px24;
                text-align: center;
                border-radius: $px30;
                line-height: 0;
                .form-title{
                    font-size: $px20;
                    font-weight: 600;
                    line-height: $px36;
                    color: #FA8A07;
                    text-transform: uppercase;
                    margin-bottom: $px20;
                }

                .form-custom{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    .btn-submit{
                        height: $px45;
                        width: $px150;
                        border-radius: $px40;
                        margin: $px12 0;
                        background: linear-gradient(180deg, #FA8A07 0%, rgba(255, 61, 0, 0.935857) 100%);
                        color: #ffffff;
                        text-transform: uppercase;
                        &:hover{
                            background: linear-gradient(180deg, rgba(250, 138, 7, 0.8) 0%, rgba(255, 61, 0, 0.8) 100%);
                        }
                    }
                    
                    .avic-input {
                        height: $px40;
                        width: 100%;
                        background: var(--commonInputBg);
                        color: var(--commonInputColor);
                        font-size: $px14;
                        padding: $px4 $px12;
                        font-weight: 400;
                        text-overflow: ellipsis;
                        border: $px1 solid #000000;
                        border-radius: $px10;
                        margin: $px6 0;
                        // font-family: 'SF Pro Display', sans-serif;
                        font-family: 'Inter', sans-serif;
                        &::placeholder {
                            color: var(--commonInputPlaceholder);
                            font-size: $px14;
                            font-weight: 400;
                        }
                        &[disabled],
                        &.ant-input-disabled {
                            background: var(--commonInputDisable);
                            color: var(--commonInputPlaceholder);
                        }
                        .ant-input-group {
                            font-size: $px14;
                            .ant-input-group-addon {
                                font-size: $px14;
                                border: $px1 solid #d9d9d9;
                                width: $px1;
                                svg {
                                    height: $px20;
                                    width: $px20;
                                }
                            }
                        }
                        .ant-input-affix-wrapper {
                            //font-size: $px14;
                            padding: $px4 $px12;
                        }
                        &.ant-input-number {
                            padding: 0;
                        }
                    }

                    .avic-text-area{
                        white-space: pre-wrap;
                        max-width: 100%;
                        height: auto !important;
                        min-height: $px32;
                        line-height: 1.5714285714285714;
                        vertical-align: bottom;
                        transition: all 0.3s,height 0s;
                    }
                }
                .ant-input{
                    min-height: $px32 !important;
                }
                .ant-row{
                    margin: 0 -$px10 !important;
                    margin-bottom: $px2 !important;
                }
                .ant-col{
                    padding: 0 $px10 !important;
                }
            }
        }
    }

    .map-wrapper{
        width: 100vw;
        height: $px360;

        &.map-mobile{
            height: calc(2 * #{$px360});
        }
    }
    //css mobile
    .section-mobile-layout{
        padding: $px160 $px40;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-attachment: fixed;
        background-repeat: no-repeat;
        background-size: cover;
        text-align: center;
        &.section1{
            color: white;
            min-height: 100vh !important;
            h1{
                font-size: $px70;
                font-weight:  700;
            }
            
            p{
                font-size: $px44;
                font-weight:  600;
            }
            
            .avic-btn{
                height: $px120;
                width: $px400;
                border-radius: $px60;
                border: $px6 solid #ffffff;
                color: #ffffff !important;
                font-size: $px40 !important;
                font-weight: 600 !important;
                background: linear-gradient(269.97deg, rgba(255, 61, 0, 0.935857) -7.8%, #FA8A07 95.56%, #FA8A07 95.56%), linear-gradient(0deg, #FFFFFF, #FFFFFF) !important;
                &:hover{                
                    background: linear-gradient(269.97deg, rgba(255, 61, 0, 0.8) -7.8%, rgba(250, 138, 7, 0.8) 95.56%, rgba(250, 138, 7, 0.8) 95.56%), linear-gradient(0deg, #FFFFFF, #FFFFFF) !important;
                }
            }
            img{
                height: auto;
                width: 80%;
                margin-top: $px80;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
            }
        }
        &.section2{
            color: white;
            h1{
                font-size: $px80;
                font-weight: 700;
                margin-top: $px40;
            }

            .div-section2{
                width: 90%;
                font-size: $px44;
                font-weight: 500;
                text-align: center;
            }

            .avic-btn{
                height: calc( 2 * #{$px86});
                width: calc( 2 * #{$px340});;
                border-radius: $px100;
                border: $px10 solid #ffffff;
                color: #ffffff !important;
                font-size: $px60 !important;
                font-weight: 700 !important;
                background: transparent !important;
                margin-bottom: $px40;
                cursor: text;
                outline: none;
            }
        }
        &.section3{
            color: white;
            h2{
                font-size: $px80;
                font-weight: 600;
            }
            .description{
                font-size: $px36;
                font-weight: 400;
                line-height: $px44;
            }
            .detail-btn{
                width: 100%;
                display:flex;
                justify-content: flex-end; 
                z-index: 100;
                a{
                    padding: $px24;
                    border-radius: $px24;
                    font-size: $px36;
                    font-weight: 400;
                    line-height: $px44;
                    color: #FFFFFF;   
                    &:hover{
                        background-color: #1e8dc1;                        ;
                    }                 
                }
            }
            img{
                height: 80%;
                width: auto;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
            }
        }
        &.section6,
        &.section4{
            h2{
                font-size: $px60;
                font-weight: 700;
                margin-bottom: $px100;
                color: #000000;
                line-height: $px72;
            }
            .list-block{
                width: 100%;
                display: flex;
                flex-direction: column;
                flex: 1;
                justify-content:center;
                align-items:center;
                .list-block-item{
                    width: 50%;
                    height: calc(2 * #{$px350});
                    padding: 0 $px80;
                    text-align: center;
                    border: $px4 solid #006FA1;
                    margin-bottom: $px120;
                    &.border-orange{
                        border-color: #FA8A07;
                    }
                    .list-block-item_icon{
                        width: $px120;
                        height: $px120;
                        margin: $px64 0;
                        &.icon_large{
                            width: auto;
                            height: calc(2 * #{$px96});
                        }
                    }
                    .list-block-item_title{
                        font-size: $px50;
                        font-weight: 600;
                        line-height: $px60;
                        color: #000000;
                        min-height: $px120;
                    }
                    .list-block-item_description{
                        font-size: $px40;
                        font-weight: 400;
                        line-height: $px48;
                        color: #000000;
                        margin: $px64 0;
                    }
                }
            }
        }
        &.section5{
            color: white;
            background-attachment: local;
            h1{
                font-size: calc(2 * #{$px40});
                font-weight: 600;
                line-height: $px96;
            }
            p{
                font-size: $px36;
                font-weight: 400;
                line-height: $px44;
                text-align: right;
            }
            .detail-btn{
                width: 100%;
                display:flex;
                justify-content: flex-end; 
                z-index: 100;
                margin-right: $px250;
                a{
                    padding: $px24;
                    border-radius: $px24;
                    font-size: $px36;
                    font-weight: 400;
                    line-height: $px44;
                    color: #FFFFFF;   
                    &:hover{
                        background-color: #1e8dc1;                        ;
                    }                 
                }
            }
        }
        &.customer-wrapper-mobile{
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-top: 0;
            padding-bottom: 0;
            .customer-title{
                width: 100vw;
                height: $px200;
                background: #006FA1;
                font-size: $px60;
                font-weight: 400;
                line-height: $px72;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #ffffff;
            }
            .customer-img{
                display: flex;
                flex: 1;
                margin: $px400 0;
                justify-content: center;
                align-items: center;
                img{
                    height: calc(2 * $px168);
                    width: auto;
                }
            }
        }
        &.section8{
            background-attachment: local;
            .form-wrapper{
                width: 80%;
                background-color: #ffffff;
                padding: $px40 $px48;
                text-align: center;
                border-radius: $px60;
                line-height: 0;
                .form-title{
                    font-size: $px40;
                    font-weight: 600;
                    line-height: $px72;
                    color: #FA8A07;
                    text-transform: uppercase;
                    margin-bottom: $px40;
                }
    
                .form-custom{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    .btn-submit{
                        height: $px90;
                        width: calc(2 * #{$px150});
                        border-radius: $px80;
                        margin: $px24 0;
                        font-size: $px28;
                        background: linear-gradient(180deg, #FA8A07 0%, rgba(255, 61, 0, 0.935857) 100%);
                        color: #ffffff;
                        text-transform: uppercase;
                        &:hover{
                            background: linear-gradient(180deg, rgba(250, 138, 7, 0.8) 0%, rgba(255, 61, 0, 0.8) 100%);
                        }
                    }
                    
                    .avic-input {
                        height: $px80;
                        width: 100%;
                        background: var(--commonInputBg);
                        color: var(--commonInputColor);
                        font-size: $px28;
                        padding: $px8 $px24;
                        font-weight: 400;
                        text-overflow: ellipsis;
                        border: $px2 solid #000000;
                        border-radius: $px20;
                        margin: $px12 0;
                        // font-family: 'SF Pro Display', sans-serif;
                        font-family: 'Inter', sans-serif;
                        &::placeholder {
                            color: var(--commonInputPlaceholder);
                            font-size: $px28;
                            font-weight: 400;
                        }
                        &[disabled],
                        &.ant-input-disabled {
                            background: var(--commonInputDisable);
                            color: var(--commonInputPlaceholder);
                        }
                        .ant-input-group {
                            font-size: $px28;
                            .ant-input-group-addon {
                                font-size: $px28;
                                border: $px2 solid #d9d9d9;
                                width: $px2;
                                svg {
                                    height: $px40;
                                    width: $px40;
                                }
                            }
                        }
                        .ant-input-affix-wrapper {
                            //font-size: $px14;
                            padding: $px8 $px24;
                        }
                        &.ant-input-number {
                            padding: 0;
                        }
                    }
    
                    .avic-text-area{
                        white-space: pre-wrap;
                        max-width: 100%;
                        height: auto !important;
                        min-height: $px64;
                        line-height: 1.5714285714285714;
                        vertical-align: bottom;
                        transition: all 0.3s,height 0s;
                    }
                }
                .ant-input{
                    min-height: $px64 !important;
                }
                .ant-row{
                    margin: 0 -$px20 !important;
                    margin-bottom: $px2 !important;
                    width: 100%;
                }
                .ant-col{
                    padding: 0 $px20 !important;
                    &:nth-child(1){
                        padding-left: 0 !important;
                    }
                    &:nth-child(2){
                        padding-right: 0 !important;
                    }
                }
            }
        }
        &.company{
            color: white;
            min-height: 100vh;
            padding: $px160;
            h3{
                font-size: $px60;
                font-weight: 600;
                margin: $px80 0;
            }
            p{
                font-size: $px40;
                font-weight:  600;
            }
            .avic-btn{
                height: $px120;
                width: $px540;
                border-radius: $px60;
                border: $px6 solid #FA8A07;
                color: #FA8A07 !important;
                font-size: $px50 !important;
                font-weight: 600 !important;
                background: #ffffff !important;
                position: relative;
                margin-bottom: $px40;
                cursor: text;
                &::after{
                    content: "";
                    display: block;
                    width: calc(2 * #{$px286});
                    height: $px150;
                    border-radius: $px80;
                    border: $px10 solid #FFFFFF; 
                    background-color: transparent;  
                    position: absolute;
                    top: -$px20;                     
                    left: -$px20;                     
                }
            }
        }
    }
}

.btn-404{
    color: #ffffff !important;
    border: none !important;
    background: linear-gradient(269.97deg, rgba(255, 61, 0, 0.935857) -7.8%, #FA8A07 95.56%, #FA8A07 95.56%), linear-gradient(0deg, #FFFFFF, #FFFFFF) !important;
    &:hover{                
        background: linear-gradient(269.97deg, rgba(255, 61, 0, 0.8) -7.8%, rgba(250, 138, 7, 0.8) 95.56%, rgba(250, 138, 7, 0.8) 95.56%), linear-gradient(0deg, #FFFFFF, #FFFFFF) !important;
    }
}
