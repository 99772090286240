.ant-table-body {
  overflow-y: auto !important;
  /* width */
  &::-webkit-scrollbar {
    width: $px5;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: $px4;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;

  }
}

.noti-container {
  .ant-badge-count {
    top: $px5;
    right: $px5;
  }
  svg {
    width: $px30;
    height: $px30;
    path {
      fill: var(--colorHeader);
    }
  }
}

.header-avatar {
  width: $px30;
  height: $px30;
  line-height: $px30;
}
.header-language {
  width: $px30;
  height: $px30;
  line-height: $px30;
}

.header-language-dropdown-item {
  width: $px20;
  height: $px20;
  line-height: $px20;
  margin-right: $px10;
}

