// base on vw = 1440px
$px1: 0.069vw;
$px2: 0.139vw;
$px3: 0.208vw;
$px4: 0.278vw;
$px5: 0.347vw;
$px6: 0.417vw;
$px7: 0.486vw;
$px8: 0.556vw;
$px9: 0.625vw;
$px10: 0.694vw;
$px11: 0.764vw;
$px12: 0.833vw;
$px13: 0.903vw;
$px14: 0.972vw;
$px15: 1.042vw;
$px16: 1.111vw;
$px17: 1.181vw;
$px18: 1.25vw;
$px19: 1.319vw;
$px20: 1.389vw;
$px21: 1.458vw;
$px22: 1.528vw;
$px23: 1.597vw;
$px24: 1.667vw;
$px25: 1.736vw;
$px26: 1.806vw;
$px28: 1.944vw;
$px30: 2.083vw;
$px32: 2.222vw;
$px34: 2.361vw;
$px35: 2.431vw;
$px36: 2.5vw;
$px40: 2.778vw;
$px42: 2.917vw;
$px43: 2.986vw;
$px44: 3.056vw;
$px45: 3.125vw;
$px48: 3.333vw;
$px50: 3.472vw;
$px55: 3.819vw;
$px60: 4.167vw;
$px64: 4.444vw;
$px65: 4.514vw;
$px68: 4.722vw;
$px70: 4.861vw;
$px72: 5vw;
$px75: 5.208vw;
$px80: 5.556vw;
$px82: 5.694vw;
$px86: 5.972vw;
$px90: 6.25vw;
$px92: 6.389vw;
$px96: 6.667vw;
$px100: 6.944vw;
$px110: 7.639vw;
$px120: 8.333vw;
$px125: 8.681vw;
$px150: 10.417vw;
$px160: 11.11vw;
$px168: 11.667vw;
$px175: 12.153vw;
$px200: 13.889vw;
$px240: 16.667vw;
$px250: 17.361vw;
$px270: 18.75vw;
$px275: 19.097vw;
$px340: 23.611vw;
$px278: 19.306vw;
$px286: 19.861vw;
$px350: 24.306vw;
$px360: 25vw;
$px380: 26.389vw;
$px390: 27.083vw;
$px400: 27.778vw;
$px450: 31.25vw;
$px520: 36.111vw;
$px540: 37.5vw;
$px600: 41.667vw;
$px650: 45.139vw;
$px900: 62.5vw;

$headerLayoutHeight: $px72;


// // base on vw = 1920px
// $px1: 0.052vw;
// $px2: 0.104vw;
// $px3: 0.156vw;
// $px4: 0.208vw;
// $px5: 0.26vw;
// $px6: 0.313vw;
// $px7: 0.365vw;
// $px8: 0.417vw;
// $px9: 0.469vw;
// $px10: 0.521vw;
// $px11: 0.573vw;
// $px12: 0.625vw;
// $px14: 0.729vw;
// $px15: 0.781vw;
// $px16: 0.833vw;
// $px17: 0.885vw;
// $px18: 0.938vw;
// $px20: 1.042vw;
// $px22: 1.146vw;
// $px24: 1.25vw;
// $px25: 1.302vw;
// $px26: 1.354vw;
// $px28: 1.458vw;
// $px30: 1.563vw;
// $px32: 1.667vw;
// $px34: 1.771vw;
// $px35: 1.823vw;
// $px40: 2.083vw;
// $px42: 2.188vw;
// $px43: 2.24vw;
// $px44: 2.292vw;
// $px45: 2.344vw;
// $px50: 2.604vw;
// $px55: 2.865vw;
// $px60: 3.125vw;
// $px65: 3.385vw;
// $px68: 3.542vw;
// $px70: 3.646vw;
// $px80: 4.167vw;
// $px82: 4.271vw;
// $px90: 4.688vw;
// $px92: 4.792vw;
// $px100: 5.208vw;
// $px110: 5.729vw;
// $px120: 6.25vw;
// $px125: 6.51vw;
// $px150: 7.813vw;
// $px160: 8.333vw;
// $px175: 9.115vw;
// $px200: 10.417vw;
// $px240: 12.5vw;
// $px250: 13.021vw;
// $px275: 14.323vw;
// $px350: 18.229vw;
// $px380: 19.792vw;
// $px390: 20.313vw;
// $px400: 20.834vw;
// $px450: 23.438vw;
// $px520: 27.083vw;
// $px650: 33.854vw;
// $px900: 46.875vw;


// $sidebarContainerWidth: 14.844vw;
// $sidebarContainerWidthCollapse: 4.688vw;

// $dashboardOrderListHeight: ($px30+$px24)*7 - $px24;
// $dashboardTodoListMaxHeight: 26.042vw;
