.customer-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    .customer-title{
        width: 100vw;
        height: $px100;
        background: #006FA1;
        font-size: $px30;
        font-weight: 400;
        line-height: $px36;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
    }
    .customer-img{
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
        img{
            height: $px168;
            width: auto;
        }
    }
}