.section-wrapper.contact-wrapper{
    .left-info{
        .contact-block{
            color: #000000;
            padding: $px60 $px40;
            width: $px540;
            background-color: rgba(255, 255, 255, 0.7);
            box-shadow: -$px20 $px20 $px20 0 rgba(0, 0, 0, 0.25);
            border-radius: $px30;
            .contact-title{
                font-size: $px20;
                font-weight: 500;
                line-height: $px24;
                text-transform: uppercase;
                width: 90%;
            }
            .contact-info{
                font-size: $px20;
                font-weight: 400;
                line-height: $px24;
                letter-spacing: 0em;  
                border-bottom: $px5 solid #FA8A07;  
                width: max-content;    
                margin: $px12 0;
                padding-bottom: $px8;
            }
            .contact-detail{
                border-radius: $px20;
                background: #FA8A07;
                margin-right: 0;
                padding: $px60 $px20;
                div{
                    font-size: $px16;
                    font-weight: 500;
                    line-height: $px20;
                    letter-spacing: 0em;
                    text-align: left;
                    margin-bottom: $px8;
                }
            }
            .contact-list-icon{
                margin-top: $px16;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                div{
                    height: $px50;
                    width: $px50;
                    margin-right: $px8;
                    cursor: pointer;
                    background-repeat: no-repeat;
                    background-size: cover;
                    border: $px8 solid #D9D9D9;
                    border-radius: 50%
                }
            }
        }
    }
    .right-info{
        .contact-svg{
            width: 100%;
            height: "auto"
        }
    }
}

.section-mobile-layout.contact-wrapper-mobile{
    .contact-svg{
        width: 100%;
        height: "auto"
    }
    .contact-block{
        margin-top: $px120;
        color: #000000;
        padding: $px120 $px80;
        width: 90%;
        background-color: rgba(255, 255, 255, 0.7);
        box-shadow: -$px240 $px240 $px240 0 rgba(0, 0, 0, 0.25);
        border-radius: $px60;
        .contact-title{
            font-size: $px40;
            font-weight: 500;
            line-height: $px48;
            text-transform: uppercase;
            width: 90%;
        }
        .contact-info{
            font-size: $px40;
            font-weight: 400;
            line-height: $px48;
            letter-spacing: 0em;  
            border-bottom: $px10 solid #FA8A07;  
            width: max-content;    
            margin: $px24 0;
            padding-bottom: $px16;
        }
        .contact-detail{
            border-radius: $px40;
            background: #FA8A07;
            margin-right: 0;
            padding: $px120 $px40;
            div{
                font-size: $px32;
                font-weight: 500;
                line-height: $px40;
                letter-spacing: 0em;
                text-align: left;
                margin-bottom: $px16;
            }
        }
        .contact-list-icon{
            margin-top: $px32;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            a{
                margin-right: $px24;
            }
            div{
                height: $px100;
                width: $px100;
                margin-right: $px16;
                cursor: pointer;
                background-repeat: no-repeat;
                background-size: cover;
                border: $px8 solid #D9D9D9;
                border-radius: 50%
            }
        }
    }
}