/// search box
.avic-search-box {
  margin-bottom: $px20;
  &.showApproveBtn{
    margin-top: $px4;
    .avic-search-box-accept{
      position: absolute;
      top: $px82;
      right: $px15;
      z-index: 100;

      .ant-btn{
        svg {
          height: $px16;
          width: $px16;
          path {
            fill: #ffffff;
          }
        }
        &.default svg path{
          fill: var(--btnDefaultBorder);
        }
      }
    }
  }

  .normal-search-box {
    .avic-input {
      height: $px45;
      padding: 0;
    }
    .ant-input-group-addon {
      padding: 0;
      .btn-normal-search {
        background: #fff;
        width: $px45;
        height: calc(#{$px45} - #{$px2});
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        border-top-right-radius: $px8;
        border-bottom-right-radius: $px8;
        svg {
          path {
            fill: var(--commonInputColor)
          }
        }
      }
    }
    .ant-input-affix-wrapper {
      height: $px45;
      //padding-left: $px20;
      //padding-right: $px20;
      background: var(--commonInputBg);
      border-top-left-radius: $px8;
      border-bottom-left-radius: $px8;
      .ant-input {
        border-left: $px1 solid #CFD9E0;
        padding-left: $px14;
        background: transparent!important;
        color: var(--commonInputColor);
        font-size: $px14;
        font-weight: 400;
        &::placeholder {
          color: var(--commonInputPlaceholder);
          font-size: $px14;
          font-weight: 400;
        }
      }

      .open-advance-search-btn {
        cursor: pointer;
        padding-right: $px10;
        padding-left: $px10;
        color: var(--commonInputColor);
        font-size: $px14;
        font-weight: 400;

        .anticon {
          padding-left: $px10;
        }
      }
    }
  }

  .advance-search-box {
    padding: $px20;
    background: #fff;
    border: $px1 solid #CFD9E0;
    box-shadow: 0 0 $px24 rgba(0, 0, 0, 0.1);
    border-radius: $px7;
    position: relative;

    .close-advance-search-btn {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: $px8 0 $px30;
      >div {
        font-size: $px18;
        font-weight: 500;
        color: var(--commonInputColor);
      }

      .advance-search-action{
        cursor: pointer;
        position: absolute;
        right: 0;
        top: 0;
        border-top-right-radius: $px7;
        height: $px68;
        .ant-space-item{
          height: 100%;
        }
        .advance-search-action-item{
          display: flex;
          align-items: center;
          justify-items: center;
          padding: 0 $px20;
          height: 100%;

          svg {
            width: $px16;
            height: $px16;
            margin-right: $px8;
            path{
              fill: #828282;
            }
          }

          &:hover{
            color: var(--btnPrimaryBg);
            background-color: rgba(131, 56, 236, 0.1);
            svg path{
              fill: var(--btnPrimaryBg);
            }
          }
        }
        &::after{
          content: "";
          display: block;
          height: $px1;
          width: 83.5vw;
          background-color:#D5D5D5;
          bottom: 0;
          left: -65vw;
          position: absolute;
        }
      }
    }

    .search-label {
      font-size: $px14;
      font-weight: 400;
      padding-bottom: $px7;
      height: $px28;
      color: var(--commonInputPlaceholder);
    }

    .form-btn-container {
      display: flex;
      justify-content: flex-end;
    }

  }
}
/// end search box

//common
.avic-form {
  label {
    font-size: $px14;
  }
  .avic-form-item {
    .ant-form-item-control-input {
      min-height: $px40;
    }

    margin-bottom: $px10;
    .css-label {
      font-size: $px14;
      font-weight: 400;
    }
    
    .ant-form-item-label {
      label {
        color: #6B7280;
        font-size: $px14;
        font-weight: 400;
        height: $px32;
        .required-mark{
          color: #ff4d4f;
        }
      }
    }
    
    .ant-radio-wrapper,
    .ant-form-item-explain-error{
      font-size: $px12;
      font-weight: 400;
      text-align: left;
    }

    .ant-radio-wrapper{
      color: #6B7280;
      margin-inline-end: $px8;
      font-size: $px14;
      .ant-radio-inner{
        width: $px16;
        height: $px16;
        border-width: $px1;
        &::after{
          width: $px16;
          height: $px16;
          margin-block-start: -$px8;
          margin-inline-start: -$px8;
          border-radius: $px16;
        }
      }
    }

    .ant-switch{
      min-width: $px44;
      height: $px22;
      margin-right: $px8;

      .ant-switch-handle{
        top: $px2;
        width: $px18;
        height: $px18;
        inset-inline-start: $px2;
        &::before{
          border-radius: $px9;
        }
      }

      &.ant-switch-checked>.ant-switch-handle{
        inset-inline-start: calc(100% - #{$px20});
      }
    }

    &.is-view {
      .required-mark {
        display: none;
      }
    }
    .ant-form-item-required {
      &:before {
        display: none!important;
      }
    }
  }

  &.ant-form-vertical {
    .ant-form-item-label {
      padding: 0 0 $px8;
      label {
        height: $px20;
      }
    }
  }
}

.avic-input-view,
.avic-select-view {
  font-size: $px14;
}

.avic-text-area{
  white-space: pre-wrap;
}

.avic-input {
  height: $px40;
  background: var(--commonInputBg);
  color: var(--commonInputColor);
  font-size: $px14;
  padding: $px4 $px12;
  border-radius: $px5;
  font-weight: 400;
  // font-family: 'SF Pro Display', sans-serif;
  font-family: 'Inter', sans-serif;
  &::placeholder {
    color: var(--commonInputPlaceholder);
    font-size: $px14;
    font-weight: 400;
  }
  &[disabled],
  &.ant-input-disabled {
    background: var(--commonInputDisable);
    color: var(--commonInputPlaceholder);
  }
  .ant-input-group {
    font-size: $px14;
    .ant-input-group-addon {
      font-size: $px14;
      border: $px1 solid #d9d9d9;
      width: $px1;
      svg {
        height: $px20;
        width: $px20;
      }
    }
  }
  .ant-input-affix-wrapper {
    //font-size: $px14;
    padding: $px4 $px12;
  }
  &.ant-input-number {
    padding: 0;
  }
}

.ant-input-number{
  font-size: $px14;
  width: 100%;
  border-radius: $px6;
  border: $px1 solid #d9d9d9;
  .ant-input-number-handler-wrap{
    width: $px30;
    border-start-end-radius: $px6;
    border-end-end-radius: $px6;
  }
  .ant-input-number-input-wrap .ant-input-number-input{
    height: $px40;
    padding: 0 $px12;
    border-radius: $px6;
    font-size: $px14;
  }

}

// CSS select
.avic-select {
  &.ant-select {
    .ant-select-arrow {
      font-size: $px12;
    }
    .ant-select-selector {
      position: relative;
      height: $px40;
      background: var(--commonInputBg);
      padding: 0 $px12;
      border-radius: $px5;
      .ant-select-selection-search-input {
        height: calc(#{$px40} - #{$px1});
      }

      .ant-select-selection-placeholder {
        height: calc(#{$px40} - #{$px1});
        line-height: calc(#{$px40} - #{$px1});
        color: var(--commonInputPlaceholder);
        font-size: $px14;
        font-weight: 400;
      }
      .ant-select-item-option-content,
      .ant-select-selection-item {
        color: var(--commonInputColor);
        line-height: $px40;
        font-size: $px14;
      }
      .ant-select-selection-search{
        inset-inline-end: $px12;
        inset-inline-start: $px12;
      }
    }
    .ant-select-clear {
      background: var(--commonInputBg);
    }
    &.ant-select-disabled {
      .ant-select-selector {
        background: var(--commonInputDisable);
      }
      .ant-select-clear {
        background: var(--commonInputDisable);
      }
    }
    &.ant-select-multiple{
      .ant-select-selector {
        min-height: $px40;
        height: unset;
      }
      .ant-select-selection-item{
        color: var(--commonInputColor);
        line-height: $px28;
        font-size: $px14;
        margin: $px2 $px4 $px2 0;
        height: $px28;
      }
      .ant-select-selection-overflow{
        // position: absolute;
        .ant-tag .ant-tag-close-icon{
          margin-inline-start: $px6;
          font-size: $px12;
        }
        
        .ant-select-selection-overflow-item{
          line-height: 1;
        }
      }
      .ant-select-selection-search {
        inset-inline-start: 0;
        inset-inline-end: $px12;
        margin-inline-start: 0;
      }
    }
  }
}

.ant-select-item {
  &.ant-select-item-option{
    min-height: $px32;
    padding: $px5 $px12;
    font-size: $px14;
    line-height: 1.5714285714285714;
    border-radius: $px4;
  }
}

// CSS datepicker
.avic-datepicker {
  &.ant-picker {
    width: 100%;
    height: $px40;
    padding: $px4 $px12 $px4;
    border-radius: $px5;
    background: var(--commonInputBg);
    &.ant-picker-disabled {
      background: var(--commonInputDisable);
    }
    .ant-picker-input {
      >input {
        font-size: $px14;
        font-weight: 400;
        color: var(--commonInputColor);

        &::placeholder {
          color: var(--commonInputPlaceholder);
          font-size: $px14;
          font-weight: 400;
        }
      }
    }
    .ant-picker-suffix svg{
      width: $px14;
      height: $px14;
    }
  }
}

// CSS range-picker
.avic-range-picker {
  &.ant-picker {
    width: 100%;
    height: $px40;
    background: var(--commonInputBg);
    padding: $px4 $px12 $px4;
    border-radius: $px5;
    font-size: $px16;
    .ant-picker-input {
      >input {
        font-size: $px14;
        font-weight: 400;
        color: var(--commonInputColor);

        &::placeholder {
          color: var(--commonInputPlaceholder);
          font-size: $px14;
          font-weight: 400;
        }
      }
    }
  }
}

/// button
.avic-btn {
  &.ant-btn {
    height: $px55;
    // line-height: calc(#{$px55} - #{$px2});
    min-width: $px160;
    font-size: $px16;
    font-weight: 700;
    padding-right: $px20;
    padding-left: $px20;
    border-radius: $px5;
    &.ant-btn-sm {
      height: $px43;
      line-height: calc(#{$px43} - #{$px2});
      min-width: $px120;
      font-size: $px14;
    }

    &.true-width {
      height: $px35;
      line-height: calc(#{$px35} - #{$px2});
      min-width: unset;
    }

    &.ant-btn-primary {
      color: var(--btnDefaultColor);
      background: var(--btnDefaultBg);
      //border: $px1 solid var(--btnDefaultBorder);
      box-shadow: 0 0 $px5 rgba(0, 0, 0, 0.25);

      &:disabled {
        opacity: 0.3;
      }
      &.default {
        color: var(--btnDefaultColor);
        background: var(--btnDefaultBg);
        //border: $px1 solid var(--btnDefaultBorder);
        &:hover {
          background: var(--btnDefaultHoverBg);
        }
        &:active {
          background: var(--btnDefaultHoverBg);
        }
      }
      &.primary {
        color: var(--btnPrimaryColor);
        background: var(--btnPrimaryBg);
        border: $px1 solid var(--btnPrimaryBorder);
        &:hover {
          background: var(--btnPrimaryHoverBg);
        }
        &:active {
          background: var(--btnPrimaryHoverBg);
        }
      }
      &.info {
        color: var(--btnInfoColor);
        background: var(--btnInfoBg);
        border: $px1 solid var(--btnInfoBorder);
        &:hover {
          background: var(--btnInfoHoverBg);
        }
        &:active {
          background: var(--btnInfoHoverBg);
        }
      }
      &.success {
        color: var(--btnSuccessColor);
        background: var(--btnSuccessBg);
        border: $px1 solid var(--btnSuccessBorder);
        &:hover {
          background: var(--btnSuccessHoverBg);
        }
        &:active {
          background: var(--btnSuccessHoverBg);
        }
      }
      &.danger {
        color: var(--btnDangerColor);
        background: var(--btnDangerBg);
        border: $px1 solid var(--btnDangerBorder);
        &:hover {
          background: var(--btnDangerHoverBg);
        }
        &:active {
          background: var(--btnDangerHoverBg);
        }
      }
      &.warning {
        color: var(--btnWarningColor);
        background: var(--btnWarningBg);
        border: $px1 solid var(--btnWarningBorder);
        &:hover {
          background: var(--btnWarningHoverBg);
        }
        &:active {
          background: var(--btnWarningHoverBg);
        }
      }

      &.ant-btn-background-ghost:not(:hover) {
        color: var(--btnDefaultColor);
        background: transparent;
        border: $px1 solid var(--btnDefaultBorder);
        &.primary {
          color: var(--btnPrimaryBg);
          border: $px1 solid var(--btnPrimaryBorder);
        }
        &.info {
          color: var(--btnInfoBg);
          border: $px1 solid var(--btnInfoBorder);
        }
        &.success {
          color: var(--btnSuccessBg);
          border: $px1 solid var(--btnSuccessBorder);
        }
        &.danger {
          color: var(--btnDangerBg);
          border: $px1 solid var(--btnDangerBorder);
        }
        &.warning {
          color: var(--btnWarningBg);
          border: $px1 solid var(--btnWarningBorder);
        }
      }
    }

    &.btn-icon-left span,
    &.btn-icon-left{
      display: flex;
      align-items: center;
      justify-content: center;
      svg{
        width: $px20;
        height: $px20;
        margin-right: $px8;
        path {
          fill: #ffffff;
        }
      }
    }

    &.btn-icon-right{
      display: flex;
      align-items: center;
      svg{
        width: $px20;
        height: $px20;
        margin-left: $px8;
        path {
          fill: #ffffff;
        }
      }
    }
    //&.ant-btn-dangerous{
    //  height: $px40;
    //  min-width: $px100;
    //  font-size: $px14;
    //  justify-content: center;
    //  color: var(--btnPrimaryColor);
    //  background: var(--btnBgDangerous);
    //  &:hover {
    //    background: var(--btnBgDangerousHover);
    //  }
    //  &:active {
    //    background: var(--btnBgDangerousActive);
    //  }
    //  &:disabled {
    //    background: var(--btnBgDangerousDisable);
    //  }
    //}
  }
}

// START create, edit modal 
.ant-modal {
  min-width: $px520 !important;
  font-size: $px14;
  top: $px80;
  .ant-modal-close{
    top: $px16;
    inset-inline-end: $px16;
    width: $px24;
    height: $px24;

    .ant-modal-close-x{
        font-size: $px16;
        font-style: normal;
        line-height: $px22;
    }
  }
  .ant-modal-content{
    padding: $px20 $px24;
  }
  .ant-modal-title{
    color: #2563EB;
    font-weight: 500;
    font-size: $px20;
  }
  .avic-form {
    .ant-form-item-explain-error,
    .ant-form-item-label label {
      font-size: $px14;
    }

  }
  .ant-modal-footer{
    margin-top: $px12;
    display: flex;
    justify-content: flex-end;
    .ant-btn{
      min-width: $px120;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.title-detail{
  font-weight: 700;
  font-size: $px30;
  color: #000000;
  margin-bottom: $px10;
}
.footer-detail{
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: $px32;
}
// END create, edit modal 

.avic-table-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: $px10;

  .avic-table-top-title {
    font-size: $px18;
    line-height: $px20;
    font-weight: 600;
    color: var(--commonInputColor)
  }

  .ant-space-item{
    display: flex;
  }
}

.avic-table-setting {
  position: absolute;
  top: $px20;
  left: $px10;
  color: var(--sidebarContentTextColor1);
  z-index: 999;
  cursor: pointer;
}

.avic-table-setting-box {
  margin-top: $px10;
  padding: $px10;
  border-radius: $px6;
  background-color: #ffffff;
  box-shadow: 0 0 $px25 rgba(0, 0, 0, 0.1);
  .avic-table-setting-box-item {
    padding-top: $px10;
    &:first-child {
      padding-top: 0;
    }
  }
}

.avic-table {
  &.ant-table-wrapper {
    table{
      border-collapse: collapse;
    }
    .ant-table-thead {
      >tr {
        >th {
          background: var(--sidebarContentBg);
          color: var(--sidebarContentTextColor1);
          padding: $px16 $px16;
          font-size: $px14;
          white-space: nowrap;
          .ant-table-column-title {
            white-space: nowrap;
          }
          //&:first-child {
          //  padding-left: $px35;
          //}
          &.ant-table-column-has-sorters {
            &:hover {
              background: var(--sidebarContentBg);
              opacity: 0.95;
              &:before {
                background-color: #f0f0f0!important;
              }
            }

            .ant-table-column-sorter {
              .anticon {
                color: var(--dashboardTextColor3);
                &.active {
                  color: var(--sidebarContentTextColor1);
                  &:before {
                    background-color: #f0f0f0!important;
                  }
                }
              }
            }
          }
          &:has(+ .ant-table-cell-scrollbar)::before{
            display: none;
          }
        }
        >td {
          background: var(--sidebarContentBg);
          color: var(--sidebarContentTextColor1);
          padding: $px16 $px16;
          font-size: $px14;
        }
      }
    }
    .ant-table-tbody{
      >tr {
        &.ant-table-row{
          >td{
            padding: $px16 $px16;
            font-size: $px14;
            //background: transparent;
            //&:first-child {
            //  border-radius: 0!important;
            //  padding-left: $px35;
            //}
            &:last-child {
              border-radius: 0!important;
            }
            //&.ant-table-column-sort {
            //  background: transparent;
            //}
          }
          //&.ant-table-row-selected{
          //
          //}
        }
      }
    }
    .ant-table-tbody {
      >tr {
        &.ant-table-row-selected{
          >td {
            background: var(--rowSelected);
          }
          &:hover{
            >td {
              background: var(--rowSelectedHover);
            }
          }
        }
      }
    }
    .ant-pagination{
      font-size: $px14;
      .ant-pagination-item{
        margin: 0 $px10;
        border-radius: $px8;
        border: $px1 solid transparent;
        min-width: $px24;
        height: $px24;
        line-height: $px22;
        a{
          padding: 0 $px6;
        }
  
        &:not(.ant-pagination-item-active) {
          background-color:#f5f5f5;
        }
  
        &:hover,
        &-active{
          background-color: var(--btnPrimaryBg);
          border-color: var(--btnPrimaryBorder);
          a {
            color: #ffffff;
          }
        }
      }
  
      .ant-pagination-jump-prev,
      .ant-pagination-jump-next,
      .ant-pagination-item-link,
      .ant-pagination-prev,
      .ant-pagination-next {
        background-color:#f5f5f5;
        border-radius: $px8;
        font-size: $px14;
        min-width: $px24;
        height: $px24;
        line-height: $px24;
      }
  
      .ant-pagination-next{
        margin-right: $px16;
      }
  
      .ant-pagination-prev{
        margin-left: $px12;
        margin-inline-end: $px8;
      }

      .ant-pagination-total-text{
        height: $px24;
        line-height: $px24;
      }

      .ant-select-selector{
        padding: 0 $px7;
        font-size: $px14;
        height: $px24;
        border-radius: $px4;
        .ant-select-selection-item{
          padding-inline-end: $px20;
          line-height: $px22;
        }
        .ant-select-selection-search{
          inset-inline-end: $px28;
          inset-inline-start: $px7;
        }
        
      }
      .ant-select-arrow{
        inset-inline-end: $px12;
        height: $px12;
        margin-top: -$px6;
        font-size: $px12;
      }
      .ant-select-dropdown{
        border-radius: $px8;
        font-size: $px14;
        padding: $px4;
        .ant-select-item-option{
          min-height: $px32;
          padding: $px5 $px12;
          font-size: $px14;
          border-radius: $px4;
        }
      }
    }
    .ant-checkbox-wrapper{
      font-size: $px14;
      .ant-checkbox{
        &::after{
          border-radius: $px4;
          border: $px2 solid #1677ff;
          font-size: $px14;
        }
        &.ant-checkbox-indeterminate .ant-checkbox-inner:after{
          width: $px8;
          height: $px8;
          border: 0;
        }
        .ant-checkbox-inner{
          width: $px16;
          height: $px16;
          border-radius: $px4;
          border: $px1 solid #d9d9d9;
          &::after{
            width: calc(#{$px6} - 0.015vw);
            height: calc(#{$px9} - 0.007vw);
            border: $px4 solid #fff;
            border-top: 0;
            border-inline-start: 0;
          }
        }
      }
    }
    .ant-table-row-expand-icon{
      margin-top: $px2;
      margin-inline-end: $px8;
      width: $px17;
      height: $px17;
      line-height: $px17;
      border: $px1 solid #D9D9D9;
      border-radius: 0;
      &::before {
        top: $px7;
        inset-inline-end: $px3;
        inset-inline-start: $px3;
        height: $px1;
      }
      &::after{
        top: $px3;
        bottom: $px3;
        inset-inline-start: $px7;
        width: $px1;
      }
    }
    .ant-table-column-sorter{
      margin-inline-start: $px4;
      .anticon{
        &.anticon-caret-up,
        &.anticon-caret-down{
          font-size: $px12;
        }
      }
    }
    .ant-table-filter-trigger{
      margin-block: -$px4;
      margin-inline: $px4 -$px8;
      padding: 0 $px4;
      font-size: $px14;
      border-radius: $px6;
      &.active svg path{
        fill: rgb(24, 144, 255);
    }
    }
    .ant-table-container table>thead>tr:first-child{
      >*:first-child{
        border-start-start-radius: $px8;
      }
      >*:last-child{
        border-start-end-radius: $px8;
      }
    }
  }

  .anticon.anticon-search > svg{
    fill: #ffffff;
  }
}

.ant-table-filter-dropdown{
  >div{
    padding: $px8;
    font-size: $px14;
    .ant-input{
      display: block;
      margin-bottom: $px8;
      padding: $px4 $px11;
      font-size: $px14;
      border-width: $px1;
      border-radius: $px6;
    }
    >.ant-space{
      gap: $px8;
    }
  }
}

.avic-vertical-step {
  &.ant-steps {
    .ant-steps-item-title {
      font-size: $px14;
      font-weight: 700;
    }
    .ant-steps-item-description {
      font-size: $px14;
    }
  }
}

.react-dropzone {
  .react-dropzone-thumb {
    margin-top: $px20;
    position: relative;
    border-radius: $px4;
    border: $px1 solid #eaeaea;
    padding: $px4;
    .react-dropzone-thumb-img {
      width: 100%;
    }
    .react-dropzone-thumb-btn {
      position: absolute;
      left: $px10;
      top: $px10;
    }
  }
}

.avatar-box-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: $px15 0 $px15 0;
  .avatar-box {
    position: relative;
    .avatar {
      background: #F3F4F6;
      color: #D1D5DB;
      width: $px175;
      height: $px175;
      font-size: $px90;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .avatar-change-icon {
      position: absolute;
      bottom: $px10;
      right: $px10;
      width: $px35;
      height: $px35;
      border-radius: 50%;
      border: $px4 solid #fff;
      background: #2F80ED;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      .ant-upload-wrapper {
        line-height: unset;
        font-size: $px20;
      }

      svg {
        width: $px18;
        height: $px16;
        path {
          fill: #fff;
        }
      }
    }
  }
}

.avic-label {
  font-size: $px14;
  font-weight: 500;
  line-height: $px28;
}

.avic-tag {
  &.ant-tag {
    font-size: $px12;
    line-height: $px22;
    padding-inline: $px10;
    color: #818181;
    border: $px1 solid #E3D4D4;
    border-radius: $px3;
    background: #ffffff;
    &.primary {
      color: var(--btnPrimaryBg);
      border: $px1 solid var(--btnPrimaryBorder);
      .ant-tag-close-icon{
        color: var(--btnPrimaryBg);
      }
    }
    &.info {
      color: var(--btnInfoBg);
      border: $px1 solid var(--btnInfoBorder);
      .ant-tag-close-icon{
        color: var(--btnInfoBg);
      }
    }
    &.success {
      color: var(--btnSuccessBg);
      border: $px1 solid var(--btnSuccessBorder);
      .ant-tag-close-icon{
        color: var(--btnSuccessBg);
      }
    }
    &.danger {
      color: var(--btnDangerBg);
      border: $px1 solid var(--btnDangerBorder);
      .ant-tag-close-icon{
        color: var(--btnDangerBg);
      }
    }
    &.warning {
      color: var(--btnWarningBg);
      border: $px1 solid var(--btnWarningBorder);
      .ant-tag-close-icon{
        color: var(--btnWarningBg);
      }
    }
  }
}

// overwrite css drop down
.ant-dropdown {
  .ant-dropdown-menu {
    .ant-dropdown-menu-item {
      font-size: $px14;
    }
    .ant-dropdown-menu-submenu {
      .ant-dropdown-menu-submenu-title {
        font-size: $px14;
        .ant-dropdown-menu-submenu-expand-icon {
          .ant-dropdown-menu-submenu-arrow-icon {
            font-size: $px12;
          }
        }

      }
    }
  }
}

// overwrite css breadcrumb
.ant-breadcrumb {
  font-size: $px14;
  a {
    font-size: $px14;
    height: $px22;
  }
}

.avic-tree {
  &.ant-tree {
    .ant-tree-checkbox+span {
      font-size: $px14;
    }
  }
}

.ant-cascader-dropdown .ant-cascader-menu {
  height: $px275;
}

.todolist-index {
  width: $px30;
  height: $px30;
  line-height: $px30;
  font-size: $px18
}

.avic-modal {
  .ant-modal-header {
    margin-bottom: $px15;
  }
}

.avic-collapse {
  .ant-collapse-header {
    align-items: center;
  }
}

.required-mark {
  color: #ff4d4f;
}

.upload-logo {
  .ant-upload-list-item {
    background: rgba(0, 0, 0, 0.01);
  }
}

// upload
.avic-upload{
  .ant-upload-wrapper{
    .ant-upload-list.ant-upload-list-text{
      height: $px100;
      overflow-y: auto;
      .ant-upload-list-item{
        height: $px22;
        margin-top: $px8;
        font-size: $px14;
      }
      .ant-upload-icon{
        svg{
          width: $px18;
          height: $px18;
        }
      }
    }
  }
}

.form-item-wrapper {
  width: 100%;
  height: $px160;
  display: flex;
  flex-direction: column;
  border: $px1 dashed #d9d9d9;

  .outer-wrapper {
      flex: 1;
      max-height: $px120;
      overflow-y: auto;
      .file-wrapper {
        margin-top: $px10;
        display: flex;
        align-items: center;
        svg {
            cursor: pointer; 
            width: $px20;
            height: $px20;
            margin: 0 0 0 $px10;
            &.icon-right{
              margin-right: $px12;
            }
        }
      }
  }

  .ant-upload-drag{
    border: none;
    border-radius: $px8
  }

  
//upload file -------start
  .upload-field {
    height: 100%;
    &.uploaded{
      height:0;
    }

    .ant-upload-btn{
      padding: $px8 0;
    }

    .ant-btn,
    .ant-form-item-control-input-content {
        box-shadow: none;
        height: 100%;
        width: 100%;
        padding: 0;
    }

    .ant-btn {
      display: flex;
      align-items: center;
      border: none;
      justify-content: center;
      color: #6B7280;
      font-size:$px16;
      svg{
        width: $px24;
        height: $px24;
      }
      &:hover{
        color: #4096ff;
        svg path {
          fill: #4096ff;
        }
      }
    }
  }

  .anchor-text {
    text-align: left;
    margin-left: $px8;
    text-decoration: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: color 0.3s;
    -webkit-text-decoration-skip: objects;
    width: 100%;
    font-size: $px16;
  }

  .text-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 90%;
  }
    
}
//upload file -------end

.shortcut-modal{
  width: 0 !important;
  .ant-modal-body{
    max-height: 50vh;
    overflow: hidden auto;
  }
}
